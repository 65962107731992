import { template as template_ae36367120bb49e9963a23f2b5a71877 } from "@ember/template-compiler";
const WelcomeHeader = template_ae36367120bb49e9963a23f2b5a71877(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

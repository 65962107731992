import { template as template_963f1de0076a4de98ddf5f12fbb7b4e6 } from "@ember/template-compiler";
const FKText = template_963f1de0076a4de98ddf5f12fbb7b4e6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

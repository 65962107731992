import { template as template_1add5c48a8ba4452ac6db3ba38151a3d } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_1add5c48a8ba4452ac6db3ba38151a3d(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_1add5c48a8ba4452ac6db3ba38151a3d(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_1add5c48a8ba4452ac6db3ba38151a3d(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;

import { template as template_0522b089e2f144c09eefa004fe3879b3 } from "@ember/template-compiler";
const FKLabel = template_0522b089e2f144c09eefa004fe3879b3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_d857885fb17a43ddb5b61cfca3857628 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d857885fb17a43ddb5b61cfca3857628(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
